export default {
  serverUrl: process.env.REACT_APP_SERVER_URL,
  // serverImageUrl: process.env.REACT_APP_SERVER_IMAGE_URL,
  // webSocketUrl: process.env.REACT_APP_WEB_SOKET_URL,
};





// {
//   "username": "SurveySameeksha",
//   "password": "ds!@#$%",
// }