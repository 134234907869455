import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import config from "../../config";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAuth } from "../../context/auth/authContext";
import axiosInstance from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { onAdminAuthorize, setSidebarData } from "../../ReduxManager/actions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const defaultTheme = createTheme();

const extractUrls = (sidebar) => {
  const urls = [];
  sidebar &&
    sidebar.forEach((module) => {
      // Add main module URL
      if (module.url) {
        urls.push(module.url);
      }

      // Add submenu URLs
      module.submenu &&
        module.submenu.forEach((submodule) => {
          if (submodule.url) {
            urls.push(submodule.url);
          }
        });
    });

  return urls;
};

export default function AuthLoginPage() {
  const { handleUserData, handleToken } = useAuth();

  // Hook for dispatching actions to Redux store
  const dispatch = useDispatch();

  // Access the isAuthenticated state from the Redux store
  const isAuthenticated = useSelector((state) => state.isAuthorised);

  const navigate = useNavigate();

  // State for managing email and password errors
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // // // If already authenticated, redirect to dashboard
  // React.useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/dashboard");
  //   }
  // }, [isAuthenticated]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    // Email validation
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //     setEmailError('Invalid email address');
    // } else {
    //     setEmailError('');
    // }
    // Password validation
    // const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // if (!passwordRegex.test(password)) {
    //     setPasswordError('Password must contain at least 8 characters, one uppercase letter, one number, and one special character');
    // } else {
    //     setPasswordError('');
    // }

    // Email validation
    if (!email) {
      setEmailError("Email cannot be empty");
    }
    // Password validation
    if (!password) {
      setPasswordError("Password cannot be empty");
    }
    if (email && password) {
      try {
        const response = await axiosInstance.post(`/login`, {
          username: email,
          password: password,
          userAgent: "GENERIC",
        });
        console.log("adminLoginResponse=>", response.data);
        if (response.data.token) {
          let userDetailsData = response.data;
          // console.log("checkToken", response.data)
          handleToken(response.data.token);
          handleUserData(response.data.token);

          // -------- Token Convert In Date  --------
          const decodedToken = jwtDecode(response.data.token);
          const tokenExpiration = decodedToken.exp;
          const expirationDate = new Date(tokenExpiration * 1000);
          axiosInstance.defaults.headers["Authorization"] =
            "Bearer " + response.data.token;
          console.log("expirationDate", expirationDate);
          // Dispatch the action to update authentication state
          axiosInstance
            .get(`/sidebar/get`)
            .then((response) => {
              // console.log("sidebar=====>>>", JSON.stringify(response.data.data));
              const userAccessUrls = extractUrls(response.data.data);
              // console.log('urls', userAccessUrls);
              const userData = {
                userProfile: userDetailsData.image_path,
                userId: userDetailsData.user.id,
                username: userDetailsData.user.username,
                userEmail: userDetailsData.user.email,
                userFullName: userDetailsData.user.full_name,
                userType: userDetailsData.user.user_sub_type,
                userRoleId: userDetailsData.user.user_role_id,
                userNumber: userDetailsData.user.contact_number,
                userAccessUrls: userAccessUrls,
                tokenExpirationDate: expirationDate,
                // menuIemsFromApi: response.data.data
              };
              dispatch(onAdminAuthorize(true, userData));
              dispatch(setSidebarData(response.data.data));

              if (userAccessUrls.length > 0) {
                // console.log("userAccessUrls==" , userAccessUrls[0])
                navigate("/dashboard");
                // navigate(userAccessUrls[0]);
              }
            });
          // navigate("/dashboard");
        }

        // Handle the response, e.g., store the token, redirect, etc.
      } catch (error) {
        console.error("Login failed:", error);
        console.log("err catch", error);
        Swal.fire({
          icon: "error",
          title: "Unauthorized User",
          text: "Please Enter valid Email and Password",
        });
      }
    }
  };

  return (
    <div className="login-container">
      <div className="context">
        <div className="area">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>

      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            className="login-card-box"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={Boolean(emailError)}
                helperText={emailError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                error={Boolean(passwordError)}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  ),
                }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                className="signin-btn"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
}
