import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Avatar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Close,
  CircleOutlined as CircleOutlinedIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu, setActiveSubMenu } from "../../../ReduxManager/actions";
// import sidebarMenuIemsFromApi from "../../../menu-items";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ProfileImage from "../../../assets/images/half-no-bg.png";
import axiosInstance from "../../../axios";
import config from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faHome, faMailBulk, faPoll, faUser, faUserShield } from "@fortawesome/free-solid-svg-icons";

// const favIcons = {
//   "fas fa-home": faHome,
//   "fas fa-users": faUser,
//   "fas fa-file-alt": faFileAlt,
//   "fas fa-poll": faPoll,
//   "fas fa-mail-bulk": faMailBulk,
//   "fas fa-user-shield": faUserShield
// }

const Sidebar = ({
  isMobileDrawerOpen,
  isSidebarToggledOpen,
  handleDrawerToggle,
}) => {
  const [isSidebarHoveredOpen, setSidebarHoveredOpen] = useState(false);
  const [shouldCollapseSubmenus, setShouldCollapseSubmenus] = useState(false);
  const isMobileView = useMediaQuery("(max-width:800px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const activeMenu = useSelector((state) => state.activeMenu);
  const userData = useSelector((state) => state.userData);
  const activeSubMenu = useSelector((state) => state.activeSubMenu);

  const sidebarMenuIemsFromApi = useSelector(
    (state) => state.sidebarDataFromApi
  );

  console.log("Current sidebar data: ", sidebarMenuIemsFromApi); // Add this log

  useEffect(() => {
    console.log("object", userData);
  }, []);

  useEffect(() => {
    if (!isSidebarToggledOpen && !isSidebarHoveredOpen) {
      setShouldCollapseSubmenus(true);
    } else {
      setShouldCollapseSubmenus(false);
    }
  }, [isSidebarToggledOpen, isSidebarHoveredOpen]);

  const handleMenuClick = (id, url) => {
    if (activeMenu === id) {
      dispatch(setActiveMenu(null));
    } else {
      dispatch(setActiveMenu(id));
    }

    if (
      sidebarMenuIemsFromApi &&
      !sidebarMenuIemsFromApi.find((item) => item.id === id).submenu.length
    ) {
      navigate(url);
    }
  };

  const handleSubMenuClick = (subItemId, url) => {
    dispatch(setActiveSubMenu(subItemId));
    navigate(url);
  };

  const handleSidebarMouseEnter = () => {
    if (!isMobileView && !isSidebarToggledOpen) {
      setSidebarHoveredOpen(true);
    }
  };

  const handleSidebarMouseLeave = () => {
    if (!isMobileView && !isSidebarToggledOpen) {
      setSidebarHoveredOpen(false);
    }
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      const isActive = activeMenu === item.id;
      return (
        <React.Fragment key={item.id}>
          <ListItem
            button
            onClick={() => handleMenuClick(item.id, item.url)}
            sx={{
              background: isActive
                ? "linear-gradient(45deg, #623aa2, #9853af)"
                : "transparent",
              color: isActive ? "#fff" : "inherit",
              transition: "background 0.3s",
              "&:hover": {
                background: "linear-gradient(45deg, #623aa2, #9853af)",
                color: "#fff",
                "& .MuiListItemIcon-root": {
                  color: "#fff",
                },
                "& .MuiListItemText-root": {
                  color: "#fff",
                },
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: isActive ? "#fff" : "inherit",
                minWidth: 0,
                marginRight:
                  isSidebarToggledOpen || isSidebarHoveredOpen ? 2 : 0,
                transition: "margin-right 0.3s",
              }}
            >
              {item?.icon ?
                <i className={item?.icon} />
                :
                <HomeOutlinedIcon />
              }
            </ListItemIcon>
            {isSidebarToggledOpen || isSidebarHoveredOpen ? (
              <ListItemText
                primary={item.disp_name}
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontWeight: "600" },
                }}
                sx={{
                  overflow: "hidden",
                  transition: "opacity 0.3s",
                  opacity: isSidebarToggledOpen || isSidebarHoveredOpen ? 1 : 0,
                }}
              />
            ) : null}
            {item.submenu.length > 0 &&
              (isSidebarToggledOpen || isSidebarHoveredOpen) ? (
              activeMenu === item.id ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </ListItem>
          {item.submenu.length > 0 && (
            <Collapse
              in={activeMenu === item.id && !shouldCollapseSubmenus}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {item.submenu.map((subItem) => {
                  const isSubActive = activeSubMenu === subItem.id;
                  return (
                    <ListItem
                      button
                      key={subItem.id}
                      onClick={() =>
                        handleSubMenuClick(subItem.id, subItem.url)
                      }
                      sx={{
                        pl: 5,
                        backgroundColor: isSubActive
                          ? "linear-gradient(45deg, #623aa2, #9853af)"
                          : "transparent",
                        color: isSubActive ? "#fff" : "inherit",
                        "&:hover": {
                          backgroundColor: "transparent",
                          "& .MuiListItemText-root": {
                            color: "#854aaa",
                          },
                        },
                        "& .MuiListItemText-root": {
                          color: isSubActive ? "#8b4dac" : "inherit",
                        },
                      }}
                    >
                      <CircleOutlinedIcon
                        sx={{
                          width: 30,
                          fontSize: 8,
                          color: isSubActive ? "#8b4dac" : "inherit",
                        }}
                      />
                      <ListItemText
                        primary={subItem.disp_name}
                        primaryTypographyProps={{
                          style: {
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: 4,
                          },
                        }}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  const drawerContent = (
    <div>
      {isMobileView && (
        <IconButton
          onClick={handleDrawerToggle}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px",
          }}
        >
          <Close />
        </IconButton>
      )}
      <div
        style={{
          textAlign: "center",
          fontSize: 14,
          fontWeight: 600,
          color: "#031b4e",
        }}
      >
        <div
          className={`sidebar-head ${isSidebarToggledOpen || isSidebarHoveredOpen ? "text-open" : ""}`}
          style={{
            height: "64px",
            fontSize: 12,
            borderBottom: "1px solid #eae8f1",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingTop: "18px",
          }}
        >
          {userData.userFullName}
        </div>
        <div className="">
          {userData.userProfile ? (
            <img
              className={`sidebar-logo ${isSidebarToggledOpen || isSidebarHoveredOpen ? "open" : ""}`}
              src={userData.userProfile}
              alt="User Name"
              style={{
                margin: "0 auto",
                marginTop: 30,
                marginBottom: 10,
                width: 30,
                height: 30,
                borderRadius: 25,
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              className={`sidebar-logo ${isSidebarToggledOpen || isSidebarHoveredOpen ? "open" : ""}`}
              src={ProfileImage}
              alt="User Name"
              style={{
                margin: "0 auto",
                marginTop: 30,
                marginBottom: 10,
                borderRadius: 25,
                objectFit: "cover",
                height: 30,
                width: 30,
                objectPosition: "top",
              }}
            />
          )}
        </div>

        <div
          style={{
            display:
              isSidebarToggledOpen || isSidebarHoveredOpen ? "block" : "none",
            transition: "opacity 0.3s",
            opacity: isSidebarToggledOpen || isSidebarHoveredOpen ? 1 : 0,
          }}
        >
          <span>Chilume Ravikumar</span>
        </div>
      </div>
      <List>{renderMenuItems(sidebarMenuIemsFromApi)}</List>
    </div>
  );

  return (
    <div style={{ display: "flex", zIndex: 4400, position: "relative" }}>
      {isMobileView ? (
        <Drawer
          variant="temporary"
          open={isMobileDrawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{ "& .MuiDrawer-paper": { width: 250 } }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          open={isSidebarToggledOpen || isSidebarHoveredOpen}
          onMouseEnter={handleSidebarMouseEnter}
          onMouseLeave={handleSidebarMouseLeave}
          sx={{
            width: isSidebarToggledOpen || isSidebarHoveredOpen ? 250 : 60,
            "& .MuiDrawer-paper": {
              width: isSidebarToggledOpen || isSidebarHoveredOpen ? 250 : 60,
              overflowX: "hidden",
              transition: "width 0.3s",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </div>
  );
};

export default Sidebar;
