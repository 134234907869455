import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


const PrivateRouter = (props) => {

	const { isAuthorised } = props

	console.log("isAuthorised", isAuthorised);

	let auth = { 'token': isAuthorised }


	return (
		auth.token ? <Outlet /> : <Navigate to="/login" />
	)
}


const mapStateToProps = state => {
	return {
		isAuthorised: state.isAuthorised,
	}
};

export default connect(mapStateToProps)(PrivateRouter)