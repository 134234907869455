import React from "react";




const Error404Page = () => {



    return (
        <>
            Error404Page
        </>
    )
}


export default Error404Page