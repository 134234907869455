import axios from 'axios';
import config from './config';


const axiosInstance = axios.create({
	baseURL: config.serverUrl,
	headers: {
		'Access-Control-Allow-Origin': '*',
		"ngrok-skip-browser-warning": true
	}
});





export default axiosInstance;
