import React from 'react';
import cookie from 'react-cookies';
import { AuthContext } from './authContext';
import { COOKIE_USER, COOKIE_TOKEN } from '../../Utils/cookies';
import axiosInstance from '../../axios';

function AuthProvider(props) {
    const [user, setUser] = React.useState(cookie.load(COOKIE_USER) || {});
    const [token, setToken] = React.useState(cookie.load(COOKIE_TOKEN) || null);
    const expires = new Date();
    expires.setDate(expires.getDate() + 6);

    React.useEffect(() => {
        if (window) {
            axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + token;
            setUser((user) => {
                return {
                    ...user,
                };
            });
        }
    }, []);

    const handleToken = (data) => {
        cookie.save(COOKIE_TOKEN, data, { path: '/', expires: expires });
        setToken(data);
    };

    const handleUserData = (data) => {
        // console.log("inside set cookie", data);
        cookie.save(COOKIE_USER, data, { path: '/', expires: expires });
        setUser(data);
    };

    const logout = () => {
        cookie.remove(COOKIE_USER, { path: '/' });
        cookie.remove(COOKIE_TOKEN, { path: '/' });
        setToken(null);
        setUser(null);
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                token,
                //function
                handleUserData,
                logout,
                handleToken,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
