import React, { useState } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";





const MainLayout = ({ reduxMasterLoader }) => {


	const [isMobileDrawerOpen, setMobileDrawerOpen] = useState(false);
	const [isSidebarToggledOpen, setSidebarToggledOpen] = useState(true);


	const handleDrawerToggle = () => {
		if (window.innerWidth <= 800) {
			setMobileDrawerOpen(!isMobileDrawerOpen);
		} else {
			setSidebarToggledOpen(!isSidebarToggledOpen);
		}
	};

	// Determine the className based on sidebar and mobile view states
	const mainPanelClassName = `main-panel ${window.innerWidth <= 800 ? 'mobile-view' :
		isSidebarToggledOpen && !isMobileDrawerOpen ? 'sidebar-open' :
			'sidebar-collapsed'
		}`;

	return (
		<div>
			{
				reduxMasterLoader &&
				<div className='admin-master-loader'>Loding</div>
			}
			<Header isSidebarToggledOpen={isSidebarToggledOpen} isMobileDrawerOpen={isMobileDrawerOpen} handleDrawerToggle={handleDrawerToggle} />
			<Sidebar isMobileDrawerOpen={isMobileDrawerOpen} isSidebarToggledOpen={isSidebarToggledOpen} handleDrawerToggle={handleDrawerToggle} />
			<div className={mainPanelClassName}>
				<div className='content-wrapper'>
					<Outlet />
				</div>
			</div>
		</div>
	)
}



const mapStateToProps = state => {
	return {
		reduxMasterLoader: state.adminMasterLoader
	}
};

export default connect(mapStateToProps)(MainLayout)