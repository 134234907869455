import React from "react";

// Lazy loading pages
const DashboardLazy = React.lazy(() => import("../Pages/HomePage"));
const DataManagementLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment")
);
////citizen data list /////
const PropertyTypeLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/PropertyType")
);
const FloorLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/Floor")
);
const OwnershipStatusLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/OwnershipStatus")
);
const ReligionLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/Religion")
);
const CasteCategoryLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/CasteCategory")
);
const MainCasteLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/MainCaste")
);
const SubCasteLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/SubCaste")
);
const MotherTongueLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/MotherTongue")
);
const RationCardTypeLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/RationCardType")
);
const CitizenStatusLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/CitizenStatus")
);
const RelationTypeLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/RelationType")
);
const RelationshipLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/Relationship")
);
const EmploymentLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/Employment")
);
const EducationalLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/Educational")
);
const IncomeLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/Income")
);
const PublicOpinionLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/CitizenDataListCard/PublicOpinion")
);
//regional data list //
const StateLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/RegionalDataListCard/State")
);
const HobaliLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/RegionalDataListCard/Hobali")
);
const DistrictLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/RegionalDataListCard/District")
);
const MainVillageLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/RegionalDataListCard/MainVillage")
);
const SubVillageLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/RegionalDataListCard/SubVillage")
);
const TalukLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/RegionalDataListCard/Taluk")
);
// electoral data //
const MPConstituencyLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/MPConstituency")
);
const MLAConstituencyLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/MLAConstituency")
);
const BoothNumberLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/BoothNumber")
);
const GramPanchayatLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/GramPanchayat")
);
const PollingStationLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/PollingStation")
);
const TalukPanchayatLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/TalukPanchayat")
);
const WardNumberLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/WardNumber")
);
const ZillaPanchayatLazy = React.lazy(() =>
  import("../Pages/Admin/DataManagment/ElectoralDataListCard/ZillaPanchayat")
);
// employee monitoring //
const EmployeeMonitoringLazy = React.lazy(() =>
  import("../Pages/Admin/EmployeeMonitoring")
);
const LoginStatusLazy = React.lazy(() =>
  import("../Pages/Admin/EmployeeMonitoring/LoginStatus")
);
const ManagerReportLazy = React.lazy(() =>
  import("../Pages/Admin/EmployeeMonitoring/ManagerReport")
);
const SupervisorReportLazy = React.lazy(() =>
  import("../Pages/Admin/EmployeeMonitoring/SupervisorReport")
);
const AuthorizerReportLazy = React.lazy(() =>
  import("../Pages/Admin/EmployeeMonitoring/AuthorizeReport")
);
const EnumeratorReportLazy = React.lazy(() =>
  import("../Pages/Admin/EmployeeMonitoring/EnumeratorReport")
);
// employe mngt//
const EmployeeManagementLazy = React.lazy(() =>
  import("../Pages/Admin/EmployeeManagement")
);
// employe mngt//
const BulkImportLazy = React.lazy(() => import("../Pages/Admin/BulkImport"));

const DataUpdationLazy = React.lazy(() =>
  import("../Pages/CitizensData/DataUpdation")
);
const DataGenerationLazy = React.lazy(() =>
  import("../Pages/CitizensData/DataGeneration")
);
const DataAnalysisLazy = React.lazy(() =>
  import("../Pages/CitizensData/DataAnalysis")
);
const BulkSmsLazy = React.lazy(() =>
  import("../Pages/DigitalMarketing/BulkSms")
);

const SurveyTitleCreationLazy = React.lazy(() =>
  import("../Pages/PrePollSurvey/SurveyTitleCreation")
);

const PrePollQuestionBankLazy = React.lazy(() =>
  import("../Pages/PrePollSurvey/PrePollQuestionBank")
);

const PrePollAnalysisLazy = React.lazy(() =>
  import("../Pages/PrePollSurvey/PrePollAnalysis")
);
const PrePollCallDashboardLazy = React.lazy(() =>
  import("../Pages/PrePollSurvey/CallDashboard")
);
const PrePollTeleCallAssignmentLazy = React.lazy(() =>
  import("../Pages/PrePollSurvey/PrePollTeleCallAssignment")
);

const ReportsLazy = React.lazy(() =>
  import("../Pages/Reports/DigitalSurveyReport")
);

const DigitalDataUpdationLazy = React.lazy(() =>
  import("../Pages/DigitalSurvey/DataUpdation")
);

const DigitalDataGenerationLazy = React.lazy(() =>
  import("../Pages/DigitalSurvey/DataGeneration")
);

const DigitalAnalysisLazy = React.lazy(() =>
  import("../Pages/DigitalSurvey/DigitalAnalysis")
);
const NewClientLazy = React.lazy(() =>
  import("../Pages/Clients/NewClient")
);

// const DigitalConfig = React.lazy(() =>
//   import("../Pages/DigitalSurvey/Config")
// );

const DataVeficationLazy = React.lazy(() =>
  import("../Pages/DigitalSurvey/DataVerification")
);
const DigitalMapLazy = React.lazy(() =>
  import("../Pages/DigitalSurvey/DigitalMap")
);
const RejectedListLazy = React.lazy(() =>
  import("../Pages/DigitalSurvey/RejectedList")
);

// Catch-all route for 404
const Error404PageLazy = React.lazy(() => import("../Pages/Error404Page"));

const routes = [
  { path: "/dashboard", name: "Dashboard", element: <DashboardLazy /> },
  {
    path: "/users",
    name: "Data Updation",
    element: <DataUpdationLazy />,
  },
  {
    path: "/client",
    name: "New Client",
    element: <NewClientLazy />,
  },
  {
    path: "/user",
    name: "Data Updation",
    element: <DataUpdationLazy />,
  },
  {
    path: "/analysis",
    name: "Digital Analysis",
    element: <DigitalAnalysisLazy />,
  },
  {
    path: "/generation",
    name: "Data Generation",
    element: <DataGenerationLazy />,
  },
  // {
  //   path: "/generation",
  //   name: "Data Generation",
  //   element: <DigitalDataGenerationLazy />,
  // },
  // {
  //   path: "/analysis",
  //   name: "Data Analysis",
  //   element: <DataAnalysisLazy />,
  // },
  {
    path: "/dataVerification",
    name: "Data Verification",
    element: <DataVeficationLazy />,
  },
  {
    path: "/map",
    name: "Digital Map",
    element: <DigitalMapLazy />,
  },
  {
    path: "/pending",
    name: "Rejected List",
    element: <RejectedListLazy />,
  },
  {
    path: "/dataManagement",
    name: "Data Management",
    element: <DataManagementLazy />,
    children: [
      {
        path: "propertyType",
        name: "Property Type",
        element: <PropertyTypeLazy />,
      },
      { path: "floor", name: "Floor", element: <FloorLazy /> },
      {
        path: "ownershipStatus",
        name: "Ownership Status",
        element: <OwnershipStatusLazy />,
      },
      {
        path: "religion",
        name: "religion",
        element: <ReligionLazy />,
      },
      {
        path: "casteCategory",
        name: "Caste CategoryLazy",
        element: <CasteCategoryLazy />,
      },
      { path: "mainCaste", name: "Main aste", element: <MainCasteLazy /> },
      { path: "subCaste", name: "Sub Caste", element: <SubCasteLazy /> },
      {
        path: "motherTongue",
        name: "Mother Tongue",
        element: <MotherTongueLazy />,
      },
      {
        path: "rationCardType",
        name: "Ration Card Type",
        element: <RationCardTypeLazy />,
      },
      {
        path: "citizenStatus",
        name: "Citizen Status",
        element: <CitizenStatusLazy />,
      },
      {
        path: "relationType",
        name: "Relation Type",
        element: <RelationTypeLazy />,
      },
      {
        path: "relationship",
        name: "Relationship",
        element: <RelationshipLazy />,
      },
      { path: "employment", name: "Employment", element: <EmploymentLazy /> },
      {
        path: "educational",
        name: "educational",
        element: <EducationalLazy />,
      },
      { path: "income", name: "income", element: <IncomeLazy /> },
      {
        path: "publicOpinion",
        name: "Public Opinion",
        element: <PublicOpinionLazy />,
      },
      //regional data //
      { path: "state", name: "State", element: <StateLazy /> },
      { path: "district", name: "District", element: <DistrictLazy /> },
      { path: "taluk", name: "Taluk", element: <TalukLazy /> },
      { path: "hobali", name: "Hobali", element: <HobaliLazy /> },
      {
        path: "main-village",
        name: "Main Village",
        element: <MainVillageLazy />,
      },
      { path: "sub-village", name: "Sub Village", element: <SubVillageLazy /> },
      // electoral data //
      {
        path: "mp-constituency",
        name: "MP-Constituency",
        element: <MPConstituencyLazy />,
      },
      {
        path: "mla-constituency",
        name: "MLA-Constituency",
        element: <MLAConstituencyLazy />,
      },
      {
        path: "zillaPanchayat",
        name: "Zilla Panchayat",
        element: <ZillaPanchayatLazy />,
      },
      {
        path: "talukPanchayat",
        name: "Taluk Panchayat",
        element: <TalukPanchayatLazy />,
      },
      {
        path: "gramPanchayat",
        name: "Gram Panchayat",
        element: <GramPanchayatLazy />,
      },
      {
        path: "pollingStation",
        name: "Polling Station",
        element: <PollingStationLazy />,
      },
      { path: "wardNumber", name: "Ward Number", element: <WardNumberLazy /> },
      {
        path: "boothNumber",
        name: "Booth Number",
        element: <BoothNumberLazy />,
      },
    ],
  },
  // employee monitoring //
  {
    path: "/userActivityMain.loginStatus",
    name: "Employee Monitoring",
    element: <EmployeeMonitoringLazy />,
  },
  // emplye mngt//
  {
    path: "/userManagement",
    name: "Employee Management",
    element: <EmployeeManagementLazy />,
  },
  {
    path: "/bulkSms",
    name: "Bulk Sms",
    element: <BulkSmsLazy />,
  },
  {
    path: "/survey",
    name: "Survey Title Creation",
    element: <SurveyTitleCreationLazy />,
  },
  
  {
    path: "/question",
    name: "PrePoll Question Bank",
    element: <PrePollQuestionBankLazy />,
  },
  
  {
    path: "/surveyAnalysis",
    name: "PrePoll Analysis",
    element: <PrePollAnalysisLazy />,
  },
  {
    path: "/surveyCallDashboard",
    name: "Call Dashboard",
    element: <PrePollCallDashboardLazy />,
  },
  {
    path: "/surveyAssignment",
    name: "Tele Call Assignment",
    element: <PrePollTeleCallAssignmentLazy />,
  },
  {
    path: "/surveyReport",
    name: "Digital Survey Report",
    element: <ReportsLazy />,
  },

  // Catch-all route for 404//
  { path: "*", name: "404", element: <Error404PageLazy /> },
  {
    path: "DataUpdation",
    name: "Data Updation",
    element: <DataUpdationLazy />,
  },

  {
    path: "/userManagement",
    name: "Employee Management",
    element: <EmployeeManagementLazy />,
  },
  {
    path: "/adminBulkImport",
    name: "Bulk Import",
    element: <BulkImportLazy />,
  },

  // Catch-all route for 404//
  { path: "*", name: "404", element: <Error404PageLazy /> },
];

export default routes;
